/* eslint-disable */
import "whatwg-fetch";

document.addEventListener("DOMContentLoaded", function () {
  var formWrapper = document.querySelector(".full-report__wrapper");
  var formMessage = document.querySelector(".full-report__message");
  var form = document.querySelector(".full-report__form");

  form.addEventListener("submit", function (event) {
    event.preventDefault();

    var formData = new FormData(form);

    fetch("app/api/full-report-form.php", {
      method: "post",
      body: formData,
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        var p = document.createElement("p");
        p.classList = "full-report__message";

        if (data.status === "success") {
          p.classList.add("full-report__message--success");
          form.parentNode.removeChild(form);
        }

        p.innerHTML = data.message;
        formWrapper.insertBefore(p, formWrapper.firstChild);
      })
      .catch(function (error) {
        var p = document.createElement("p");
        p.classList = "full-report__message";
        p.innerHTML = "Request failure: " + error;
      });
  });
});
